import { useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import { Color } from '../../_shared/enums/color';
import PageLayout, { PageLayoutBackground } from '../../components/common/page-layout/page-layout';
import Button, { ButtonThemeColor, ButtonThemeType } from '../../components/templates/button/button';
import HeadlineCopytext from '../../components/templates/headline-copytext/headline-copytext';
import ResponsiveImage from '../../components/templates/responsive-image/responsive-image';

import DownloadsStyles from './_scss/downloads.module.scss';

const DownloadsPage = () => {
    const intl = useIntl();

    return (
        <PageLayout
            title={intl.formatMessage({ id: 'pages.downloads.title' })}
            description={intl.formatMessage({ id: 'pages.downloads.description' })}
            pageBackground={PageLayoutBackground.PinkBlack}
        >
            <div className={DownloadsStyles.downloadsSection}>
                <div className={DownloadsStyles.downloadsContentCycle}>
                    <HeadlineCopytext
                        color={Color.Pink}
                        alignment="left"
                        headline={[
                            {
                                style: 'h3',
                                text: 'HIER FINDEST DU ALLES',
                            },
                            {
                                style: 'h4',
                                text: 'ZUM @CYCLE:',
                            },
                        ]}
                    >
                        <div className={DownloadsStyles.downloadsButtons}>
                            <Button
                                type="link"
                                href="/downloads/Gebrauchsanleitung@Cycle.pdf"
                                target="_blank"
                                theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Pink }}
                            >
                                GEBRAUCHSANLEITUNG DOWNLOADEN
                            </Button>
                        </div>
                        <div>
                            <Button
                                type="link"
                                href="/downloads/Produktdatenblatt@Cycle.pdf"
                                target="_blank"
                                theme={{ type: ButtonThemeType.Gradient, color: ButtonThemeColor.PinkAqua }}
                            >
                                PRODUKTDATENBLATT DOWNLOADEN
                            </Button>
                        </div>
                    </HeadlineCopytext>
                </div>
                <div className={DownloadsStyles.downloadsImageCycle}>
                    <ResponsiveImage
                        images={[
                            {
                                path: '_assets/pages/service/downloads/cycle.png',
                                media: '',
                            },
                        ]}
                        altText=""
                    />
                </div>
            </div>

            <div className={[DownloadsStyles.downloadsSection, 'gradient-background-static-black-blue'].join(' ')}>
                <div className={DownloadsStyles.downloadsContentMirror}>
                    <HeadlineCopytext
                        color={Color.Purple}
                        alignment="left"
                        headline={[
                            {
                                style: 'h3',
                                text: 'HIER FINDEST DU ALLES',
                            },
                            {
                                style: 'h4',
                                text: 'ZUM @MIRROR:',
                            },
                        ]}
                    >
                        <div className={DownloadsStyles.downloadsButtons}>
                            <Button
                                type="link"
                                href="/downloads/Gebrauchsanleitung@Mirror.pdf"
                                target="_blank"
                                theme={{ type: ButtonThemeType.SolidWhite, color: ButtonThemeColor.Purple }}
                            >
                                GEBRAUCHSANLEITUNG DOWNLOADEN
                            </Button>
                        </div>
                        <div>
                            <Button
                                type="link"
                                href="/downloads/Produktdatenblatt@Mirror.pdf"
                                target="_blank"
                                theme={{ type: ButtonThemeType.Gradient, color: ButtonThemeColor.PurpleGreen }}
                            >
                                PRODUKTDATENBLATT DOWNLOADEN
                            </Button>
                        </div>
                    </HeadlineCopytext>
                </div>
                <div className={DownloadsStyles.downloadsImageMirror}>
                    <ResponsiveImage
                        images={[
                            {
                                path: '_assets/pages/service/downloads/mirror.png',
                                media: '',
                            },
                        ]}
                        altText=""
                    />

                    <div className={DownloadsStyles.downloadsImageMirrorNote}>
                        <p className="color-white">
                            <small>Standfuß optional erhältlich</small>
                        </p>
                    </div>
                </div>
            </div>

            <div className={DownloadsStyles.downloadsWarrantyCard} id="warranty_card">
                <h3>GARANTIEKARTE HORIZON@:</h3>
                <Button
                    type="link"
                    href="/downloads/Garantiekarte@horizon.pdf"
                    target="_blank"
                    theme={{ type: ButtonThemeType.SolidBlack, color: ButtonThemeColor.White }}
                >
                    GARANTIEKARTE DOWNLOADEN
                </Button>
            </div>
        </PageLayout>
    );
};

export default DownloadsPage;
